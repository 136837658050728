import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import routes from './router'
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
	// ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
	ak: '7Lv3xXBWzT7W4tx2KqUqKNtcrxyYoFuF'
})

Vue.use(VueRouter)

Vue.config.productionTip = false
window.RequestHost = ""

const router = new VueRouter({
	routes: routes
})

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')