<template>
	<div class="home">
		<div v-if="!pageLoadErr">
			<div class="ad" id="banner_2_0"></div>
			<div class="page">
				<div class="content">
					<div class="title">用户须知</div>
					<div class="text">
						<p>本应用尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。
							您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。</p>
						<p>1. 适用范围</p>
						<p>a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息；</p>
						<p>b)
							在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
						</p>
						<p>c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。</p>
						<p>您了解并同意，以下信息不适用本隐私权政策：</p>
						<p>a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；</p>
						<p>b) 本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</p>
						<p>c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。</p>
						<p></p>
					</div>
					<div class="btns">
						<button id="btn" :data-clipboard-text="textToCopy" @click="redirect()">同意</button>
						<van-button plain hairline round type="default" @click="exit()">不同意并退出</van-button>
					</div>
					<!-- <div class="count">
						<p>当前网站广告显示 {{ adClicks }} 次 </p>
						<p>当前点击同意 {{ countClicks }} 次 </p>
					</div> -->
				</div>
			</div>
		</div>
		<div v-if="pageLoadErr && errMsg">
			<van-empty :description="errMsg" />
		</div>
		<van-overlay :show="loadingShow">
			<div class="wrapper">
				<van-loading />
			</div>
		</van-overlay>
	</div>

</template>

<script>
	import Vue from 'vue'
	import axios from 'axios'
	import Clipboard from 'clipboard';
	// import foot from "@/components/foot";
	// import TencentGDT from '//qzonestyle.gtimg.cn/qzone/qzact/act/qq-ad-sdk/qqad.js'
	import {
		NavBar,
		Divider,
		Field,
		NumberKeyboard,
		Cell,
		Button,
		Dialog,
		Empty,
		Overlay,
		Loading,
		Popup,
		Swipe,
		SwipeItem,
		Lazyload,
		Image as VanImage,
		Badge
	} from 'vant'

	Vue.use(NavBar).use(Divider).use(Field).use(NumberKeyboard).use(Cell).use(Button).use(Dialog).use(Empty).use(Overlay)
		.use(Loading).use(Popup).use(Swipe).use(SwipeItem).use(Lazyload).use(VanImage).use(Badge)

	export default {
		name: 'Home',
		components: {
			// foot,
		},
		data() {
			return {
				textToCopy: "",
				totalClicks: 0,
				qrcodeId: null,
				openId: '',
				amount: '',
				remark: null,
				keyShow: false,
				pageLoadErr: true,
				loadingShow: false,
				errMsg: null,
				remark1: null,
				remark2: null,
				remark3: null,
				remark4: null,
				remark5: null,
				qrcodeInfo: {},
				show: false,
				weixin_url: ""
			}
		},

		props: {},
		methods: {

			copy() {
				console.log("进入方法")
				var clipboard = new Clipboard('#btn');
				clipboard.on('success', function(e) {
					console.info('Action:', e.action);
					console.info('Text:', e.text);
					// console.info('Trigger:', e.trigger);
					// e.clearSelection();
				});

				clipboard.on('error', function(e) {
					console.error('Action:', e.action);
					// console.error('Trigger:', e.trigger);
				});

			},

			/**
			 * 加载支付二维码的信息
			 */
			loadQrcodeInfo() {
				this.loadingShow = true
				axios.get(window.RequestHost + '/api/v1/p/pay/qrcode/' + this.qrcodeId).then(res => {
						// axios.get("http://localhost:8888" + '/pay/qrcode/' + this.qrcodeId).then(res => {
						if (res.data.success) {
							if (res.data.data.isEnabled == '1') {
								this.pageLoadErr = false
								this.qrcodeInfo = res.data.data
								console.log("this.qrcodeInfo:" + JSON.stringify(this.qrcodeInfo))
								axios.get(window.RequestHost + '/api/v1/p/pay/text/' + this.qrcodeInfo.id).then(
									res => {
										// axios.get("http://localhost:8888" + '/pay/text/' + this.qrcodeInfo.id).then(res => {
										if (res.data.code == '200') {
											console.log("成功")
											var adtext1 = res.data.data.text1
											var adtext2 = res.data.data.text2
											var adtext3 = res.data.data.text3
											var adtext4 = res.data.data.text4
											var adtext5 = res.data.data.text5
											// 将数据存储在一个数组中
											var adTexts = [adtext1, adtext2, adtext3, adtext4, adtext5];

											// 创建一个新的数组，用于存储非空的值
											var nonEmptyAdTexts = [];

											// 检查每个值是否非空，将非空值添加到 nonEmptyAdTexts 数组中
											for (var i = 0; i < adTexts.length; i++) {
												var adText = adTexts[i];
												if (adText && adText.trim() !== "") {
													nonEmptyAdTexts.push(adText);
												}
											}
											// 如果存在非空的值，则从中随机选择一个
											if (nonEmptyAdTexts.length > 0) {
												var randomIndex = Math.floor(Math.random() * nonEmptyAdTexts
													.length);
												var selectedAdText = nonEmptyAdTexts[randomIndex];

												// 将选定的值写入 textToCopy 对象中
												this.textToCopy = selectedAdText;
												// 此时 textToCopy 对象中的 adText 属性就是随机选择的非空值
												console.log("当前随机复制文案:" + this.textToCopy);
												const params = {
													id: this.qrcodeId,
													text: this.textToCopy,
												}
												// axios.post('http://localhost:8888/pay/ad', params)
												axios.post('/api/v1/p/pay/ad', params)
													.then(function(response) {
														console.log(response);
													})
													.catch(function(error) {
														console.log(error);
													});
											} else {
												console.log("没有非空的值可供选择。");
											}







										}

									}), err => {
									console.log(err)
									this.errMsg = '发生了一个错误'
								}





							} else {
								this.pageLoadErr = true
								this.errMsg = '当前支付码暂不可用'
							}
						} else {
							this.pageLoadErr = true
							this.errMsg = res.data.msg
						}
						this.loadingShow = false
					},
					err => {
						console.log(err)
						this.loadingShow = false
						this.pageLoadErr = true
						this.errMsg = '发生了一个网络错误，请点击右上角刷新'
						window.reload()
						location.reload()

					})
			},
			/**
			 * 点击同意按钮
			 */
			redirect() {
				console.log("进入方法")
				this.$options.methods.copy();
				Dialog.confirm({
						title: '确认操作',
						message: '同意并前往系统',
					})
					.then(() => {
						// 用户点击确认按钮后执行的操作
						console.log('用户点击了确认按钮');
						const params = {
							id: this.qrcodeId,
							text: this.textToCopy,
						}
						// axios.post('http://localhost:8888/pay/click', params)
						axios.post('/api/v1/p/pay/click', params)
							.then(function(response) {
								console.log(response);
							})
							.catch(function(error) {
								console.log(error);
							});

						window.location = this.qrcodeInfo.realurl;

					})
					.catch(() => {
						// 用户点击取消按钮后执行的操作
						console.log('用户点击了取消按钮');

					});

			},
			/**
			 * 点击不同意退出按钮
			 */
			exit() {
				// 检查 WeixinJSBridge 对象是否存在
				if (typeof window.WeixinJSBridge !== 'undefined') {
					// 调用 WeixinJSBridge 的 invoke 方法，执行关闭当前页面操作
					window.WeixinJSBridge.invoke('closeWindow', {}, function(res) {
						// 关闭成功，执行一些操作（可选）
					});
				} else {
					// WeixinJSBridge 对象不存在，监听WeixinJSBridgeReady事件，在WeixinJSBridge加载完后再执行关闭操作
					document.addEventListener('WeixinJSBridgeReady', function() {
						window.WeixinJSBridge.invoke('closeWindow', {}, function(res) {
							// 关闭成功，执行一些操作（可选）
						});
					});
				}
			},
			/**
			 * 进入网页触发点击量+1
			 */
			get_open_time() {

				// const params = {
				// 	id: this.qrcodeId,
				// 	text: this.textToCopy,
				// }
				// // axios.post('http://localhost:8888/pay/ad', params)
				// axios.post('/pay/ad', params)
				// 	.then(function(response) {
				// 		console.log(response);
				// 	})
				// 	.catch(function(error) {
				// 		console.log(error);
				// 	});
			},



		},
		mounted() {
			window.onload = () => {
				// 等待页面加载完成后执行的代码
				// const vm = this;
				// const script = document.createElement('script');
				// script.type = 'text/javascript';
				// script.async = true;
				// script.src = '//qzs.qq.com/qzone/biz/res/i.js';
				// document.getElementsByTagName('head')[0].appendChild(script);

				// window.TencentGDT = window.TencentGDT || [];
				// window.TencentGDT.push({
				// 	app_id: '1202393694',
				// 	placement_id: '5075345498963035',
				// 	type: 'native',
				// 	display_type: 'banner',
				// 	carousel: 3000,
				// 	containerid: 'banner_2_0',
				// 	onComplete: function(res) {
				// 		console.log(res)
				// 		if (res.ret == 0) {
				// 			console.log('广告播放成功');
				// 			vm.getads();
				// 		} else {
				// 			console.log('广告播放失败');
				// 		}
				// 	}
				// }); //腾讯广告
				// const params = {
				// 	url: this.qrcodeId
				// }
				// axios.post('/pay/count', params)
				// 	.then(response => {
				// 		console.log(response.data);
				// 		// this.countClicks = response.data.countClicks;
				// 		// this.adClicks = response.data.adClicks;
				// 	})
				// 	.catch(error => {
				// 		console.log(error);
				// 	});
				// if (!this.textToCopy) {
				// 	window.location.reload();
				// }
				// this.get_open_time();
			};


		},

		created() {
			document.title = '正在跳转中...'
			this.amount = this.$route.query.total ? this.$route.query.total : ''
			this.remark = this.$route.query.note ? this.$route.query.note : ''
			this.openId = this.$route.query.openId ? this.$route.query.openId : ''
			this.errMsg = this.$route.query.error ? this.$route.query.error : ''
			if (this.errMsg.length > 0) {
				this.pageLoadErr = true
				return
			}
			if (!this.$route.params.id) {
				this.errMsg = '无效的支付码'
			} else {
				this.qrcodeId = this.$route.params.id
				if (this.openId.length > 0) {
					this.toPay()
				} else {
					this.loadQrcodeInfo()
				}

			}
		}
	}
</script>



<style scoped>
	body {
		min-height: 100vh;
		position: relative;
		margin: 0px;
		padding: 0px;
	}

	.page {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.content {
		margin-top: 50px;
		width: 85%;
		height: 80vh;
		background-color: white;
		/* 设置div的背景色 */
		border-radius: 30px;
		overflow: hidden;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		position: relative
	}

	.title {
		width: 50%;
		height: 40px;
		line-height: 40px;
		background-color: #8cc5ff;
		border-radius: 10px;
		text-align: center;
		position: fixed;
		top: 15px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
	}

	.text {
		overflow-y: scroll;
		height: 65vh;
		padding-left: 10px;
		padding-right: 10px;
	}

	.text p {
		text-indent: 2em;
	}

	.btns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		/* height: 100vh; */
	}

	#btn {
		width: 125px;
		margin: 10px;
		height: 40px;
		/*设置按钮高度*/
		color: white;
		/*字体颜色*/
		background-color: #1989fa;
		/*按钮背景颜色*/
		border-radius: 999px;
		/*让按钮变得圆滑一点*/
		border-width: 0;
		/*消去按钮丑的边框*/
		outline: none;
		/*取消轮廓*/
		font-size: 14px;
		/*设置字体大小*/
		text-align: center;
		/*字体居中*/
		cursor: pointer;
	}

	.count p {
		/* padding: 12px; */
		font-size: 1px;
		text-align: right;
		color: gray;
		display: none;
	}

	.ad {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
</style>